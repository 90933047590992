import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Modal, Row, Spin, Tooltip, Upload } from 'antd';
import Select from '../../components-v2/Select';
import Input from '../../components-v2/Input';
import Radio from '../../components-v2/Radio';
import Wizard, { WizardProps } from '../../components-v2/Wizard';
import { apiClient } from '../../utils/apiClient';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import ToolTipIcon from '../.././../assets/Icon/ToolTipIcon';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import { useHistory } from 'react-router-dom';
import { dataActions } from '../../redux/store';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  ApiClientType,
  CUSTOM_FORMAT,
  IS_TEST_IMPORT_TOGGLE_ON,
  OFFICIAL_FORMAT,
  PUBLISHED_STATUS,
  TEST_STATUS,
  uniqueIdentifierGroup,
  listOfUniqueIdentifiers,
  FileTypes,
  FileUploadButtonText,
} from '../../utils/constants';
import { UploadRequestMethod } from '../../interfaces/Common';
import { availabilityCheck } from '../../utils/availabilityCheck';
import { DataTypesType } from '../../interfaces/DataTypesType';

interface TestScoreFileUploadProps extends WizardProps {
  allFieldsRequired: boolean;
  defaultMapping: Record<string, string>;
  translateConfigEndPoint: string;
  header?: React.ReactElement;
  specificMapping?: Record<string, string>;
}

const { Option } = Select;

const TSFileUpload = (props: TestScoreFileUploadProps): React.ReactElement => {
  const history = useHistory();
  const [selectedFileName, setSelectedFileName] = React.useState(FileUploadButtonText.NO_FILE_CHOSEN);
  const [chooseOrReplaceFile, setChooseOrReplaceFile] = React.useState(FileUploadButtonText.CHOOSE_FILE);
  const [inputFileValue, setInputFileValue] = React.useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [dataTypes, setdataTypes] = React.useState<DataTypesType[]>([]);
  const [dataTypeSelected, setDataTypeSelected] = React.useState(false);
  const [fileChosen, setFileChosen] = React.useState(false);
  const [selectedDataType, setSelectedDataType] = React.useState<DataTypesType>();
  const [dataTypesLoading, setdataTypesLoading] = React.useState(true);
  const [disableUniqueIdentifier, setDisableUniqueIdentifier] = React.useState(true); // true, if format is official
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const MAX_FILE_SIZE_BYTES = 1e7; // 10MB
  const groupingId = useSelector((state: RootStateOrAny) => state.groupingId);
  const groupingName = useSelector((state: RootStateOrAny) => state.groupingName);
  const dataTypeId = useSelector((state: RootStateOrAny) => state.dataTypeId);
  const dataTypeName = useSelector((state: RootStateOrAny) => state.dataTypeName);
  const fileName = useSelector((state: RootStateOrAny) => state.fileName);
  const fields = useSelector((state: RootStateOrAny) => state.fieldsData);
  const dataTypeFormat = useSelector((state: RootStateOrAny) => state.dataTypeFormat);
  const firstRowContainColumnHeadings = useSelector((state: RootStateOrAny) => state.firstRowContainColumnHeadings);
  const uniqueIdentifier = useSelector((state: RootStateOrAny) => state.uniqueIdentifier);
  const uniqueIdentifierKey = useSelector((state: RootStateOrAny) => state.uniqueIdentifierKey);
  const officialFileFormatLink = useSelector((state: RootStateOrAny) => state.officialFileFormatLink);
  const [fileDataUpdatedLoader, setFileDataUpdatedLoader] = React.useState(false);
  const [dataTypeUpdatedLoader, setDataTypeUpdatedLoader] = React.useState(false);
  const [selectedUniqueIdentifier, setSelectedUniqueIdentifier] = React.useState('');
  const dataImportPath = '/data-import-new';
  const dispatch = useDispatch();
  let updatedFields = JSON.parse(JSON.stringify(fields));
  const fileHeaders = useSelector((state: RootStateOrAny) => state.fileHeaders);

  const processData = (columnData, dataRows) => {
    const headers = columnData.map((item, index) => item + '_' + index);
    const list = [];
    for (let i = 0; i < dataRows.length; i++) {
      //read and store first 5 rows only
      const row = dataRows[i];

      if (headers && row.length == headers.length) {
        const obj = {};

        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    dispatch(dataActions.addFileheaders(columns));
    dispatch(dataActions.addFileDataAllRows(list));

    const firstThreeRows = list.slice(0, list.length > 3 ? 3 : list.length);
    dispatch(dataActions.addFileDataFirstThreeRows(firstThreeRows));
    setFileDataUpdatedLoader(false);
  };

  const uniqueIdentifierValues = [
    { key: 'keyGroupA', value: 'First Name, Last Name, and Birthdate' },
    { key: 'keyGroupB', value: 'Student ID and Last Name' },
    { key: 'keyGroupC', value: 'State Student ID and Last Name' },
  ];

  const toolTipText =
    'The data in your file that should be used to uniquely identify a student in an upload. Files in official format already have unique identifiers determined.';

  const save = async (saveAndContinue?: boolean) => {
    try {
      if (props.allFieldsRequired) {
        showNotification(NotificationTypes.error, 'Please map all required fields before Saving', '');
        return;
      }
      if (saveAndContinue) {
        if (dataTypeFormat === CUSTOM_FORMAT) updateUniqueIdentifierRequiredFlag();
        setLoading(true);
        const { data } = await apiClient(
          {
            url: `/testscore/datatype/${dataTypeId}/parameter-group/translateConfig?uniqueIdentifierKey=${uniqueIdentifierKey}&headerLessFile=${!firstRowContainColumnHeadings}`,
            method: 'post',
            data: {
              records: [...updatedFields],
              fileHeaders: fileHeaders,
              dataTypeFormat: dataTypeFormat,
            },
          },
          ApiClientType.DATA_INGEST_TOOL,
        );
        const configData = { ...data };
        const valueMappings = configData?.translateConfig?.valueMappings ?? {};
        let acceptanceValues = [];
        const updatedValueMapper = {};
        const valueMapper = (acceptanceValue) => {
          if (Array.isArray(acceptanceValue?.fromValue)) {
            acceptanceValue?.fromValue?.map((fromValue) => {
              acceptanceValues.push({ toValue: acceptanceValue?.toValue, fromValue: fromValue });
            });
            return acceptanceValues;
          }
          return acceptanceValues.push(acceptanceValue);
        };

        for (const [key, value] of Object.entries(valueMappings)) {
          acceptanceValues = [];
          valueMappings[key].forEach((acceptanceValue) => valueMapper(acceptanceValue));
          updatedValueMapper[key] = acceptanceValues;
        }

        data.translateConfig.valueMappings = updatedValueMapper;
        dispatch(dataActions.addConfig(data));
        dispatch(dataActions.addModifiedConfig(data));
        setLoading(false);
        props.continueFn();
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Mapping', 'Server Error');
    }
  };

  const cancel = (cancelAndBacktrack?: boolean) => {
    if (cancelAndBacktrack) {
      props.previousFn();
    } else {
      dispatch(dataActions.resetReduxStates());
      props.cancelFn();
    }
  };
  const changeStep = (newStep: number) => {
    props.changeStepFn(newStep);
  };

  const fetchDataTypesData = async () => {
    try {
      const { data } = await apiClient(
        { url: `/grouping/${groupingId}/datatype`, method: 'get' },
        ApiClientType.TEST_SCORE,
      );
      //if there are no data types, show modal and redirect user

      if (data.length === 0) {
        setIsModalVisible(true);
      } else if (
        //  continue, if atleast one data type
        //     1.Is in published status and belongs to all states or the users state
        //     2.Is in test status and isTestImportToggleIsOn (test user) is true, and  belongs to all states or the users state
        data.some((item) => {
          return (
            (item.status === PUBLISHED_STATUS || (item.status === TEST_STATUS && IS_TEST_IMPORT_TOGGLE_ON)) &&
            availabilityCheck(item)
          );
        })
      ) {
        setdataTypes(data);
        setdataTypesLoading(false);
      } else {
        // there are no datatypes in published status. Show Modal and redirect user
        setIsModalVisible(true);
      }
    } catch (error) {
      if (groupingId === '') {
        showNotification(
          NotificationTypes.error,
          'Error Getting Data Types',
          'Unable to fetch data types due to page being refreshed.Redirecting to main page',
        );
        history.push('/');
      } else {
        showNotification(NotificationTypes.error, 'Error Getting Data Types', 'Failure in getting data from server.');
      }
    }
  };

  //disable Unique Identifier dropdown for official format
  const changeDataType = async (value: string, event) => {
    setDataTypeSelected(true);
    dispatch(dataActions.addDataTypeId(event.key));
    if (event.datatype.keyGroup != null) {
      dispatch(dataActions.setUniqueIdentifierKey(`keyGroup${event.datatype.keyGroup}`));
    }
    dispatch(dataActions.addDataTypeName(event.datatype.name));
    setSelectedDataType(event.datatype);
    if (event.datatypeformat === OFFICIAL_FORMAT) {
      setDisableUniqueIdentifier(true);
      dispatch(dataActions.addDataTypeFormat(event.datatypeformat));
    } else {
      setDisableUniqueIdentifier(false);
      dispatch(dataActions.addDataTypeFormat(event.datatypeformat));
    }
    if (event.datatype?.url) {
      dispatch(dataActions.setofficialFileFormatLink(event.datatype.url));
    } else {
      dispatch(dataActions.setofficialFileFormatLink(''));
    }
    setDataTypeUpdatedLoader(true);
    dispatch(dataActions.addFieldsData([]));
    const { data } = await apiClient({ url: `/datatype/${event.key}/field`, method: 'get' }, ApiClientType.TEST_SCORE);
    dispatch(dataActions.addFieldsData(data));
    setDataTypeUpdatedLoader(false);
  };

  //Close Modal handler, redirect user to import home page
  const handleCancel = () => {
    setIsModalVisible(false);
    history.push(dataImportPath + '/data-import-new');
  };

  const changeUniqueiIdentifier = (value: string, e) => {
    dispatch(dataActions.setUniqueIdentifierKey(e.key));
    dispatch(dataActions.setUniqueIdentifier(uniqueIdentifierGroup[e.key]));
  };

  const removeFile = () => {
    dispatch(dataActions.removeSelectedFileName());
    setSelectedFileName(FileUploadButtonText.NO_FILE_CHOSEN);
    setInputFileValue('');
    setChooseOrReplaceFile(FileUploadButtonText.CHOOSE_FILE);
    setErrorMessage('');
    setFileChosen(false);
  };

  const method: UploadRequestMethod = 'POST';
  const uploadProps = {
    action: '/file-upload',
    method: method,
    showUploadList: false,
    accept: FileTypes,
    multiple: false,
    beforeUpload(file) {
      const isValidFile = FileTypes.includes(file.type);
      if (!isValidFile) {
        setErrorMessage(`${file.name} is an invalid file format. Only .csv, .txt and .zip files are accepted.`);
        return false;
      }
      if (file.size >= MAX_FILE_SIZE_BYTES) {
        setErrorMessage('File size should be less than 10MB.');
        return false;
      }
    },
    onStart() {
      setErrorMessage('');
      setFileDataUpdatedLoader(true);
    },
    onSuccess({ data: { keyName, columns, rows } }, file: { name: React.SetStateAction<string> }) {
      setSelectedFileName(file.name);
      setChooseOrReplaceFile(FileUploadButtonText.REPLACE_FILE);
      setInputFileValue(file.name);
      setErrorMessage('');
      setFileChosen(true);
      dispatch(dataActions.addFileName(file.name));
      dispatch(dataActions.addUploadedFileName(keyName));
      processData(columns, rows);
    },
    onError(error) {
      dispatch(dataActions.removeSelectedFileName());
      setFileDataUpdatedLoader(false);
      setFileChosen(false);
      setInputFileValue('');
      setSelectedFileName(FileUploadButtonText.NO_FILE_CHOSEN);
      setErrorMessage(error.response.data.message.error);
    },
    customRequest({ file, onError, onSuccess, action, method }) {
      const formData = new FormData();
      formData.append('group', groupingId);
      formData.append('firstRowIsHeader', '1');
      formData.append('file', file);
      apiClient({ url: action, method: method, data: formData }, ApiClientType.DATA_INGEST_TOOL)
        .then(onSuccess)
        .catch(onError);
    },
  };

  React.useEffect(() => {
    fetchDataTypesData();
    if (fileName) {
      setChooseOrReplaceFile(FileUploadButtonText.REPLACE_FILE);
    }
  }, []);
  React.useEffect(() => {
    const getuniqueIdentifierValues = uniqueIdentifierValues.find((item) => item.key === uniqueIdentifierKey)?.value;
    setSelectedUniqueIdentifier(getuniqueIdentifierValues);
  }, [uniqueIdentifierKey, dataTypeId]);

  const updateUniqueIdentifierRequiredFlag = () => {
    const uniqueIdentiers = uniqueIdentifierGroup[uniqueIdentifierKey];
    updatedFields.map((item) => {
      if (listOfUniqueIdentifiers.find((field) => field === item.id)) {
        if (uniqueIdentiers.includes(item.id)) item.required = true;
        else item.required = false;
      }
    });
    dispatch(dataActions.addFieldsData(updatedFields));
  };

  const mainContent = (
    <>
      <div className="subSections" style={{ height: '100vh' }}>
        <div className="subSection">
          <Col>
            <p className="subsection-header">Choose a File</p>
          </Col>
          <Col>
            <span className="subsection-file-details">
              All .csv, .txt, and .zip files are accepted. Max size of 10MB.
            </span>
          </Col>
          <Col style={{ marginTop: '15px' }}>
            <div>
              <Row align="middle">
                <Col>
                  <Upload {...uploadProps}>
                    <button className="button choose-file">{chooseOrReplaceFile}</button>
                  </Upload>
                </Col>
                <Col>
                  <span className="no-file-choosen">{fileName ? fileName : FileUploadButtonText.NO_FILE_CHOSEN}</span>
                </Col>
                <Col>
                  <span className="close-outline">
                    <CloseOutlined onClick={removeFile} style={fileName ? { color: '#141497' } : { display: 'none' }} />
                  </span>
                </Col>
                <Col>
                  <span className="validation-error-message" style={errorMessage !== '' ? {} : { display: 'none' }}>
                    <AlertErrorIcon></AlertErrorIcon>
                    <span>{errorMessage}</span>
                  </span>
                </Col>
              </Row>
              {fileDataUpdatedLoader && (
                <Col span={10} style={{ marginTop: '15px' }}>
                  <Spin>
                    <Alert message="Uploading file..." description=" " type="info" />
                  </Spin>
                </Col>
              )}
            </div>
          </Col>
        </div>
        <Col span={10}>
          <Divider style={{ marginTop: '20px', marginBottom: '35px' }} />
        </Col>
        <Col span={10}>
          <Row className="subsection-file-details" style={{ marginTop: '15px' }}>
            File Name (when sending via SFTP)
          </Row>
          <Input
            placeholder=""
            className="drawerInput"
            style={{ height: '40px', maxWidth: '100%' }}
            value={fileName}
          ></Input>
        </Col>
        <Col span={10}>
          <Row className="subsection-file-details" style={{ marginTop: '15px' }}>
            Data Type
          </Row>
          <Select
            value={dataTypeName === '' ? '' : dataTypeName}
            placeholder="Choose a Data Type"
            onChange={changeDataType}
            className="select select-text"
            loading={dataTypesLoading}
          >
            {/* display data type if
            1.It is in published status and belongs to all states or the users state
            2.it is in test status and isTestImportToggleIsOn (test user) is true, and  belongs to all states or the users state
             */}
            {dataTypes.map((item) => {
              return (item.status === PUBLISHED_STATUS || (item.status === TEST_STATUS && IS_TEST_IMPORT_TOGGLE_ON)) &&
                availabilityCheck(item) ? (
                <Option key={item.id} value={item.name} datatypeformat={item.format} datatype={item}>
                  {item.name}
                </Option>
              ) : null;
            })}
          </Select>
          {dataTypeUpdatedLoader && (
            <Col style={{ marginTop: '15px' }}>
              <Spin>
                <Alert message="Updating..." description=" " type="info" />
              </Spin>
            </Col>
          )}
        </Col>
        {officialFileFormatLink && (
          <a download href={officialFileFormatLink} className="download-official-text">
            Download official file format
          </a>
        )}
        <Col span={10}>
          <Row
            className={`subsection-file-details ${disableUniqueIdentifier ? 'select-heading' : ''}`}
            style={{ marginTop: '15px' }}
          >
            Unique Identifier
            <Tooltip placement="bottomLeft" title={toolTipText}>
              <div>
                <ToolTipIcon className="tool-tip-identifier" inactive={true} />
              </div>
            </Tooltip>
          </Row>

          <Select
            value={selectedUniqueIdentifier}
            placeholder="Choose a Unique Identifier"
            onChange={changeUniqueiIdentifier}
            className={`select ${disableUniqueIdentifier ? 'select-active' : ''}`}
            disabled={dataTypeFormat === CUSTOM_FORMAT ? false : true}
          >
            {uniqueIdentifierValues.map((item) => {
              return (
                <Option key={item.key} value={item.value}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Col>
        <div
          className="radioValue"
          style={{ marginTop: '20px', fontSize: '16px', fontWeight: '400', color: '#333333' }}
        >
          <Row>Does the first row of your file contain column headings?</Row>
          <Radio.Group
            defaultValue={true}
            value={firstRowContainColumnHeadings}
            onChange={() => {
              dispatch(dataActions.setFirstRowContainColumnHeadings());
            }}
          >
            <Col span="auto">
              <Radio className="radio-item" value={true} style={{ fontFamily: 'Open Sans' }}>
                Yes
              </Radio>
              <Radio className="radio-item" value={false} style={{ fontFamily: 'Open Sans' }}>
                No
              </Radio>
            </Col>
          </Radio.Group>
          <Modal
            title="Alert"
            visible={isModalVisible}
            onCancel={handleCancel}
            centered
            footer={[
              <Button key="cancel" type="primary" onClick={handleCancel}>
                Go Back
              </Button>,
            ]}
          >
            <p className="modal-text">No data types found for group name {groupingName}</p>
          </Modal>
        </div>
      </div>
    </>
  );

  return (
    <Wizard
      steps={props.steps}
      currentStep={props.currentStep}
      continueFn={() => {
        if (!dataTypeFormat && !fileName) {
          showNotification(NotificationTypes.error, 'Please select a data type and choose a file', '');
        } else if (!dataTypeFormat) {
          showNotification(NotificationTypes.error, 'Please select a data type', '');
        } else if (!fileName) {
          showNotification(NotificationTypes.error, 'Please Choose a file', '');
        } else if (fileDataUpdatedLoader) {
          showNotification(NotificationTypes.error, 'Please wait for the file to be uploaded', '');
        } else if (updatedFields.length === 0) {
          showNotification(NotificationTypes.error, 'Please wait for data type to be updated', '');
        } else {
          save(true);
        }
      }}
      previousFn={() => cancel(true)}
      cancelFn={() => cancel()}
      changeStepFn={changeStep}
      hideHeaderButtons={true}
      pageLoading={loading}
    >
      {mainContent}
    </Wizard>
  );
};

export default TSFileUpload;
